import {
  AuditOutlined,
  CloudDownloadOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  ExceptionOutlined,
  FileSearchOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ReadOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { Button, Layout, Menu, MenuProps, theme } from 'antd'
import { t } from 'i18next'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { memo, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import logo from '../assets/images/gnz-logo.svg'
import {
  permissionActions,
  permissionNames,
  permissionValues,
} from '../constants/permissionConstant'
import { routePaths } from '../pages/RootPage'
import roleService from '../services/roleService'
import { RootState } from '../store'

const { useToken } = theme
const { Sider } = Layout

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0 8px 0;
  min-height: 36px;
  width: 100%;
`

type MenuItem = Required<MenuProps>['items'][number]

interface MenuItemKey {
  SEARCH: string
  LITERATURE_REVIEW: string
  USER: string
  DATA_SOURCE: string
  DATA_STORE: string
  WORKSPACE: string
  AUDIT_TRAIL: string
  OFR: string
}
const menuItemKey: MenuItemKey = {
  SEARCH: 'search',
  LITERATURE_REVIEW: 'literatureReview',
  USER: 'user',
  DATA_SOURCE: 'dataSource',
  DATA_STORE: 'dataStore',
  WORKSPACE: 'workspace',
  AUDIT_TRAIL: 'auditTrail',
  OFR: 'ofr',
}

interface MainMenuProps {
  collapsed: boolean
  setCollapsed: (value: boolean) => void
}

const StyledSider = styled(Sider)`
  && .ant-layout-sider-trigger {
    background: ${(props) => {
      return props?.style?.background || 'transparent'
    }} !important;
  }
`

const MainMenu = memo(({ collapsed, setCollapsed }: MainMenuProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { token } = useToken()
  const currentUser = useSelector((state: RootState) => state.user.currentUser)

  const getSelectedKey = useCallback(() => {
    const basePath = `/${location.pathname.split('/')[1]}`
    switch (basePath) {
      case routePaths.WORKSPACE_LIST:
      case routePaths.WORKSPACE_DETAILS:
      case routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REFAI:
        return menuItemKey.WORKSPACE
      case routePaths.SEARCH:
        return menuItemKey.SEARCH
      case routePaths.LITERATURE_REVIEW_LIST:
      case routePaths.LITERATURE_REVIEW_DETAILS:
      case routePaths.LITERATURE_REVIEW_SEARCH:
      case routePaths.LITERATURE_REVIEW_DOCUMENT:
        return menuItemKey.LITERATURE_REVIEW
      case routePaths.DATA_SOURCE_LIST:
      case routePaths.DATA_SOURCE_DETAILS:
        return menuItemKey.DATA_SOURCE
      case routePaths.USER_LIST:
      case routePaths.USER_DETAILS:
        return menuItemKey.USER
      case routePaths.OFR_DOCUMENT_TEMPLATE_LIST:
      case routePaths.OFR_DOCUMENT_TEMPLATE_DETAILS:
        return menuItemKey.OFR
    }
  }, [location])

  const getItem = useCallback(
    (
      label: React.ReactNode,
      key: React.Key,
      icon?: React.ReactNode,
      children?: MenuItem[]
    ): MenuItem => {
      return {
        key,
        icon,
        children,
        label,
      } as MenuItem
    },
    []
  )

  const items: MenuItem[] = useMemo(
    () => [
      getItem(
        t('mainMenu.workspace') || '',
        menuItemKey.WORKSPACE,
        <ContainerOutlined />
      ),
      getItem(
        t('mainMenu.search') || '',
        menuItemKey.SEARCH,
        <FileSearchOutlined />
      ),
      getItem(
        t('mainMenu.literatureReview') || '',
        menuItemKey.LITERATURE_REVIEW,
        <ReadOutlined />
      ),
      { type: 'divider' },
      ...(currentUser &&
      roleService.hasPermission(
        currentUser,
        permissionNames.DATA_SOURCES,
        permissionActions.ALL
      )
        ? [
            getItem(
              t('mainMenu.dataSources') || '',
              menuItemKey.DATA_SOURCE,
              <DatabaseOutlined />
            ),
            getItem(
              t('mainMenu.dataStores') || '',
              menuItemKey.DATA_STORE,
              <CloudDownloadOutlined />
            ),
          ]
        : []),
      getItem(
        t('mainMenu.userManagement') || '',
        menuItemKey.USER,
        <TeamOutlined />
      ),
      getItem(t('mainMenu.ofr') || '', menuItemKey.OFR, <ExceptionOutlined />),
      ...(currentUser &&
      roleService.hasPermission(
        currentUser,
        permissionNames.AUDIT_TRAILS,
        permissionActions.READ,
        permissionValues.ALL
      )
        ? [
            getItem(
              t('mainMenu.auditTrails') || '',
              menuItemKey.AUDIT_TRAIL,
              <AuditOutlined />
            ),
          ]
        : []),
    ],
    [getItem, currentUser]
  )

  const handleMenuClick: MenuClickEventHandler = useCallback(
    (menuInfo) => {
      switch (menuInfo.key) {
        case menuItemKey.WORKSPACE:
          navigate(routePaths.WORKSPACE_LIST)
          break
        case menuItemKey.SEARCH:
          navigate(routePaths.SEARCH)
          break
        case menuItemKey.LITERATURE_REVIEW:
          navigate(routePaths.LITERATURE_REVIEW_LIST)
          break
        case menuItemKey.DATA_SOURCE:
          navigate(routePaths.DATA_SOURCE_LIST)
          break
        case menuItemKey.DATA_STORE:
          navigate(routePaths.DATA_STORE_LIST)
          break
        case menuItemKey.USER:
          navigate(routePaths.USER_LIST)
          break
        case menuItemKey.AUDIT_TRAIL:
          navigate(routePaths.AUDIT_TRAIL_LIST)
          break
        case menuItemKey.OFR:
          navigate(routePaths.OFR_DOCUMENT_TEMPLATE_LIST)
          break
      }
    },
    [navigate]
  )

  return (
    <StyledSider
      collapsible
      theme="light"
      collapsed={collapsed}
      style={{
        minHeight: '100vh',
        backgroundColor: token.colorBgContainer,
        background: token.colorBgContainer,
        position: 'fixed',
        zIndex: 200,
        color: token.colorText,
      }}
      onCollapse={(value) => setCollapsed(value)}
      trigger={
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            backgroundColor: token.colorBgContainer,
            color: token.colorText,
            fontSize: 16,
            width: 64,
            height: 40,
            bottom: 8,
          }}
        />
      }
    >
      <LogoContainer>
        <img src={logo} alt="Logo" />
      </LogoContainer>
      <Menu
        defaultSelectedKeys={[getSelectedKey() || menuItemKey.SEARCH]}
        selectedKeys={[getSelectedKey() || '']}
        mode="inline"
        items={items}
        onClick={handleMenuClick}
        style={{ borderWidth: 0 }}
      />
    </StyledSider>
  )
})

export default MainMenu
