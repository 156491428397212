import {
  ExportOutlined,
  MoreOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import { MenuProps } from 'antd/lib'
import { t } from 'i18next'
import { MouseEvent, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  permissionActions,
  permissionNames,
} from '../constants/permissionConstant'
import roleService from '../services/roleService'
import { RootState } from '../store'

interface PageContentDropdownProps {
  onExport?: () => void
  disabled?: boolean
}

const PageContentDropdown = ({
  onExport,
  disabled,
}: PageContentDropdownProps) => {
  const { currentUser } = useSelector((state: RootState) => state.user)
  const dropdownKeys = {
    printContent: 'dropdown-content-print-content',
    exportContent: 'dropdown-content-export-content',
  }

  const printContentItem = {
    key: dropdownKeys.printContent,
    icon: <PrinterOutlined />,
    label: t('printContent'),
  }

  const exportContentItem = {
    key: dropdownKeys.exportContent,
    icon: <ExportOutlined />,
    label: t('exportContent'),
  }

  // Check if export content item should be included
  const shouldIncludeExportContent = () => {
    return (
      currentUser &&
      roleService.hasPermission(
        currentUser,
        permissionNames.ADMIN,
        permissionActions.ALL
      )
    )
  }

  const items: MenuProps['items'] = [
    printContentItem, // Always include print content item
    ...(shouldIncludeExportContent() ? [exportContentItem] : []), // Include export content item only if user is admin
  ]

  const handleDropdownClick = (key: string) => {
    if (key === dropdownKeys.printContent) {
      window.print()
    } else if (key === dropdownKeys.exportContent) {
      if (onExport) {
        onExport()
      }
      return
    }
  }

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
  }, [])

  return (
    <Dropdown
      menu={{
        // @ts-ignore: Unreachable code error
        items,
        onClick: ({ key, domEvent }) => {
          domEvent.stopPropagation()
          handleDropdownClick(key)
        },
      }}
      placement="bottomRight"
      trigger={['click']}
      disabled={disabled}
    >
      <Button
        icon={<MoreOutlined />}
        onClick={(e) => {
          handleOpenMenu(e)
        }}
      />
    </Dropdown>
  )
}

export default PageContentDropdown
