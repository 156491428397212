import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { NO_DATA_PLACEHOLDER } from '../../constants/constants'
import { RootState } from '../../store'
import { SearchResultItem } from '../../store/reducers/searchResultInfoReducer'
import SearchDocumentSummariesPageHeader from './SearchDocumentSummariesPageHeader/SearchDocumentSummariesPageHeader'
import i18n from '../../i18n/i18n'

export interface SearchDocumentSummariesTableRow {
  id: string
  key: string
  title?: string
  summary?: string
}

const SearchDocumentSummariesPage = () => {
  const searchResultInfo: SearchResultItem[] | undefined = useSelector(
    (state: RootState) => state.searchResultInfo.searchResult?.data
  )

  const formattedData: SearchDocumentSummariesTableRow[] = useMemo(
    () =>
      (searchResultInfo || []).map((document, index: number) => {
        const language = i18n.language
        const title =
          typeof document?.title === 'string'
            ? document.title
            : document?.title?.[language] ?? NO_DATA_PLACEHOLDER
        const summary =
          typeof document?.summary === 'string'
            ? document.summary
            : document?.summary?.[language] ?? ''

        return {
          key: index.toString(),
          id: document._id ?? `missing-id-${index}`,
          title,
          summary,
        }
      }),
    [searchResultInfo]
  )

  return (
    <>
      <SearchDocumentSummariesPageHeader />
      SearchDocumentSummariesPage - {formattedData.length}
    </>
  )
}

export default SearchDocumentSummariesPage
